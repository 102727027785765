.ant-table-row:hover .comfortPlusDetails {
    visibility: visible !important;
}

.ant-table-row .comfortPlusDetails {
    visibility: hidden !important;
}

.comfortPlusDetailModal {
    margin: 0 0 0 auto !important;
    height: 100%;
    top: 0;
    bottom: 0;
    padding: 0;
    .ant-modal-content {
        height: 100%;
        .ant-modal-footer {
            height: 0;
            padding: 0;
            border: 0 none;
        }
    }
    .headerModal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            flex: 1;
        }
        button {
            flex: 0;
        }
    }
    .detail span {
        display: flex;
        align-items: center;
        height: 100%;
    }

}

.deleteGuestConfirmModalIcon {
    .svg-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.comfortPlusConfirmModal {
    .deleteGuestComfirmModalContent {
        .title {
            width: 100%;
            text-align: center;
            font-size: 18px;
        }
        .content {
            text-align: center;
        }
    }
    
    .deleteGuestRemoveButton {
        background: #E8472E !important;
        border-color: #E8472E !important;
    }

    .deleteGuestRemoveButton:hover .deleteGuestRemoveButton:focus {
        background: #a22d1b !important;
        border-color: #a22d1b !important;
    }

    .deleteGuestRemoveButton:disabled {
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
    }

    .ant-modal-confirm-btns {
        display: flex;
        justify-content: center;
    }

    .ant-modal-footer {
        padding: 0;
    }
}