.fullly-mapped-alert {
  margin-bottom: 60px;
}

.fullly-mapped-alert .check-icon {
  font-size: 42px;
  display: inline-block;
  vertical-align: top;
}

.fullly-mapped-alert .alert-text {
  display: inline-block;
  margin-left: 22px;
}

.fullly-mapped-alert .alert-text .alert-message {
  color: var(--mine-shaft);
  font-size: 30px;
  margin-bottom: 0;
}

.fullly-mapped-alert .alert-text .alert-description {
  color: var(--scorpion);
  font-size: 20px;
  margin-bottom: 0;
}
