@import "styles/mixinStyles";

.compressor {
  background-color: white;
  border: 1px solid var(--silver);
  padding: 16px;
  margin: 16px;

  .compressor-header {
    font-size: 24px;

    .name {
      font-weight: 900;
    }

    .action {
      cursor: pointer;
      color: black;

      &.anticon-setting {
        margin-right: 16px;
      }
    }
  }

  .compressor-section {
    font-size: 12px;
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: unset;
    }

    .ant-divider {
      margin: unset;
    }

    .compressor-type {
      font-size: 24px;
    }

    .room-connected-counter {
      font-size: 14px;
    }

    .empty-node-connected {
      .message {
        margin-right: 20px;
      }

      button {
        @include default-button;

        color: white;
        background-color: var(--gray);
      }
    }

    .node-connected {
      .ant-row {
        padding: 5px 0;
        align-items: center;

        .group {
          p {
            margin-bottom: unset;
          }

          .node-mac {
            font-size: 14px;
            text-transform: uppercase;
          }

          .node-type {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 12px;
          }

          .last-seen-title {
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .node-status {
          border-radius: 5px;
          text-align: center;
          width: 60px;
          padding: 5px 5px;
          font-weight: bold;

          span {
            text-transform: uppercase;
            font-size: 12px;
            color: white;
          }

          &.online {
            background-color: var(--persian-green);
          }

          &.offline {
            background-color: var(--razzmatazz);
          }
        }
      }
    }
  }
}
