@import "styles/global";

.compressor-section {
  margin: 25px 0;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .compressor-type {
    line-height: 1;
  }

  .line-between {
    border-left: 1px solid gray;
  }
}

.infra-details-table {
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  height: calc(100vh - 280px); // make sure that table always inside the screen
  overflow: auto;

  &.connected-rooms-table,
  &.mapped-nodes-table {
    .ant-table-cell {
      padding: 16px 8px;
    }

    .ant-table-thead {
      .ant-table-cell {
        background-color: var(--mercury);
        text-transform: uppercase;

        .header-title {
          font-weight: bolder;
        }
      }
    }
  }

  .header-section {
    margin-bottom: 16px;
    align-items: center;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;
    width: 100%;
    height: calc(100vh - 400px);
    margin-bottom: 20px;
  }
}

.button-action {
  @include default-button;

  background-color: var(--sapphire);
  color: white;
}

.infrastructure-type-selector {
  width: 100% !important;
}
