.wrapper {
  letter-spacing: 1px;
  line-height: 1.2em;
}

.online {
  color: var(--lima);
}

.offline {
  color: var(--burnt-sienna);
}

.booted {
  color: var(--deep-cerulean);
}