.user-form {
  &-location {
    &-button-container {
      position: absolute;
      z-index: 1;
      top: -30px;
      right: 0;
      button {
        padding: 0px 0px 0px 15px;
      }
    }
  }
}
