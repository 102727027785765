@import "./variables.scss";

@mixin default-button {
  background: var(--deep-cerulean);
  border-radius: 2px;
  border-color: unset;
}

@mixin default-modal {
  .ant-modal-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--mine-shaft);
  }

  .ant-btn {
    border-radius: 2px;
    background: white;
  }

  .ant-btn-primary {
    background: var(--deep-cerulean);
    border-color: var(--deep-cerulean);
  }
}

@mixin heading-1 {
  h1 {
    color: var(--mine-shaft);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

@mixin media-breakpoint-down($screen-size) {
  @media (max-width: map-get($grid-breakpoints, $screen-size)) {
    @content;
  }
}
