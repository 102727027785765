.troubleshoot-modal {
  .ant-modal-title {
    color: var(--mine-shaft);
    font-size: 16px;
    font-weight: 600;
  }

  .troubleshoot-subtext {
    color: var(--scorpion);
    font-size: 12px;
    font-weight: 400;
  }

  .anticon-cloud-download, .anticon-mail, .anticon-thunderbolt, .anticon-tool, .anticon-wifi {
    color: var(--deep-cerulean);
    margin-bottom: 20px;
    margin-top: 10px;

    svg {
      width: 32px;
      height: 36px;
    }
  }

  .troubleshoot-step {
    color: var(--mine-shaft);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .troubleshoot-description {
    color: var(--scorpion);
    font-size: 12px;
    font-weight: 400;
  }
}
