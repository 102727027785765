.confirm-delete-modal-icon {
    background-color: var(--burnt-sienna) !important;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    svg {
        width: 35px;
        height: 35px;
    }
}

.delete-group-modal {
    .ant-modal-confirm-btns {
        text-align: center !important;
    }
}