.dashboardContainer {
  padding: 24px;
}

.dashboardHeader {
  margin-bottom: 24px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardsContainer {
  margin-bottom: 24px;
}

.dashboardCard {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: all 0.3s ease;
  height: 100%;
  
  :global {
    .ant-card-head {
      border-bottom: 1px solid #f0f0f0;
      min-height: 48px;
      
      .ant-card-head-title {
        padding: 12px 24px;
      }
    }
    
    .ant-card-body {
      transition: all 0.3s ease;
    }
  }
}

.cardTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.cardActions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.detailedViewButton {
  font-size: 16px;
  font-weight: 500;
  
  &:hover {
    color: #1890ff;
    background: transparent;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.holisticsBoardContainer {
  width: 100%;
  padding: 16px;
}

.holisticsIframe {
  width: 100%;
  min-height: 1200px;
  border: none;
}

// Detailed view modal
.detailedViewModal {
  :global {
    .ant-modal-body {
      padding: 24px;
    }
    
    .ant-modal-content {
      height: 90vh;
      display: flex;
      flex-direction: column;
    }
    
    .ant-modal-header {
      padding: 16px 24px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}

.detailedViewContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  iframe {
    height: calc(90vh - 120px) !important; /* Account for modal header and padding */
    flex-grow: 1;
    min-height: 0 !important; /* Override min-height to allow flexible sizing */
    width: 100%;
    border: none;
  }
}

// Float button navigation styles
.floatButtonGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 100;
}

.navButton {
  width: 44px !important;
  height: 44px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-color: #e8e8e8;
  
  &:hover {
    background-color: #f0f0f0;
    border-color: #d9d9d9;
    transform: scale(1.05);
  }
  
  &:focus {
    background-color: #fff;
    border-color: #e8e8e8;
  }
  
  transition: all 0.3s ease;
} 