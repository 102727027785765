.note {
  background: #cbeafb;
  padding: 34px 16px 24px 16px;
  border-radius: 8px;
}

.card-title {
  color: #313131;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 22px;
}

ul {
  margin-top: 8px;
}

li {
  color: #5f5f5f;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 22px;
}

.floating-icon {
  justify-content: center;
  position: absolute;
  top: -60px;
  overflow: visible;
}

.earth-bg {
  height: 46px;
  width: 46px;
  border-radius: 9999px;
  align-items: center;
  justify-content: center;
}

.overlap-container {
  position: relative;
  justify-content: center;
}
