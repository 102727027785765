// Survey Theme Variables - Only applied to the survey form
.smart-bea-container {
  // SurveyJS specific variables
  --sjs-corner-radius: 4px;
  --sjs-base-unit: 8px;
  --sjs-shadow-small: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  --sjs-shadow-inner: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  --sjs-border-default: rgba(0, 0, 0, 0.16);
  --sjs-border-light: rgba(0, 0, 0, 0.09);
  --sjs-general-backcolor: rgba(255, 255, 255, 1);
  --sjs-general-backcolor-dark: rgba(248, 248, 248, 1);
  --sjs-general-backcolor-dim-light: rgba(249, 249, 249, 1);
  --sjs-general-backcolor-dim-dark: rgba(243, 243, 243, 1);
  --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-general-dim-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-dim-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-secondary-backcolor: rgba(255, 152, 20, 1);
  --sjs-secondary-backcolor-light: rgba(255, 152, 20, 0.1);
  --sjs-secondary-backcolor-semi-light: rgba(255, 152, 20, 0.25);
  --sjs-secondary-forecolor: rgba(255, 255, 255, 1);
  --sjs-secondary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-shadow-small-reset: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  --sjs-shadow-medium: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  --sjs-shadow-inner-reset: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  --sjs-border-inside: rgba(0, 0, 0, 0.16);
  --sjs-special-red-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-green: rgba(25, 179, 148, 1);
  --sjs-special-green-light: rgba(25, 179, 148, 0.1);
  --sjs-special-green-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-blue: rgba(67, 127, 217, 1);
  --sjs-special-blue-light: rgba(67, 127, 217, 0.1);
  --sjs-special-blue-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-yellow: rgba(255, 152, 20, 1);
  --sjs-special-yellow-light: rgba(255, 152, 20, 0.1);
  --sjs-special-yellow-forecolor: rgba(255, 255, 255, 1);
  --sjs-article-font-xx-large-textDecoration: none;
  --sjs-article-font-xx-large-fontWeight: 700;
  --sjs-article-font-xx-large-fontStyle: normal;
  --sjs-article-font-xx-large-fontStretch: normal;
  --sjs-article-font-xx-large-letterSpacing: 0;
  --sjs-article-font-xx-large-lineHeight: 64px;
  --sjs-article-font-xx-large-paragraphIndent: 0px;
  --sjs-article-font-xx-large-textCase: none;
  --sjs-article-font-x-large-textDecoration: none;
  --sjs-article-font-x-large-fontWeight: 700;
  --sjs-article-font-x-large-fontStyle: normal;
  --sjs-article-font-x-large-fontStretch: normal;
  --sjs-article-font-x-large-letterSpacing: 0;
  --sjs-article-font-x-large-lineHeight: 56px;
  --sjs-article-font-x-large-paragraphIndent: 0px;
  --sjs-article-font-x-large-textCase: none;
  --sjs-article-font-large-textDecoration: none;
  --sjs-article-font-large-fontWeight: 700;
  --sjs-article-font-large-fontStyle: normal;
  --sjs-article-font-large-fontStretch: normal;
  --sjs-article-font-large-letterSpacing: 0;
  --sjs-article-font-large-lineHeight: 40px;
  --sjs-article-font-large-paragraphIndent: 0px;
  --sjs-article-font-large-textCase: none;
  --sjs-article-font-medium-textDecoration: none;
  --sjs-article-font-medium-fontWeight: 700;
  --sjs-article-font-medium-fontStyle: normal;
  --sjs-article-font-medium-fontStretch: normal;
  --sjs-article-font-medium-letterSpacing: 0;
  --sjs-article-font-medium-lineHeight: 32px;
  --sjs-article-font-medium-paragraphIndent: 0px;
  --sjs-article-font-medium-textCase: none;
  --sjs-article-font-default-textDecoration: none;
  --sjs-article-font-default-fontWeight: 400;
  --sjs-article-font-default-fontStyle: normal;
  --sjs-article-font-default-fontStretch: normal;
  --sjs-article-font-default-letterSpacing: 0;
  --sjs-article-font-default-lineHeight: 28px;
  --sjs-article-font-default-paragraphIndent: 0px;
  --sjs-article-font-default-textCase: none;
  --sjs-general-backcolor-dim: #FFF7ED;
  --sjs-primary-backcolor: #002952;
  --sjs-primary-backcolor-dark: rgba(0, 34, 67, 1);
  --sjs-primary-backcolor-light: rgba(0, 41, 82, 0.1);
  --sjs-primary-forecolor: rgba(255, 255, 255, 1);
  --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-special-red: rgba(229, 10, 62, 1);
  --sjs-special-red-light: rgba(229, 10, 62, 0.1);

  // Custom theme classes for survey components
  background-color: var(--sjs-general-backcolor);
  color: var(--sjs-general-forecolor);
  border-radius: var(--sjs-corner-radius);
  box-shadow: var(--sjs-shadow-medium);
  
  .sv-root-modern {
    --primary: var(--sjs-primary-backcolor);
    --primary-light: var(--sjs-primary-backcolor-light);
    --secondary: var(--sjs-secondary-backcolor);
    --background: var(--sjs-general-backcolor);
    --background-dim: var(--sjs-general-backcolor-dim);
    --border-color: var(--sjs-border-default);
  }
  
  .sv-btn {
    border-radius: var(--sjs-corner-radius);
  }
  
  .sv-btn--navigation {
    background-color: var(--sjs-primary-backcolor);
    color: var(--sjs-primary-forecolor);
    
    &:hover {
      background-color: var(--sjs-primary-backcolor-dark);
    }
  }
  
  .sv-title {
    color: var(--sjs-primary-backcolor);
  }
  
  .sv-question__title {
    font-weight: var(--sjs-article-font-medium-fontWeight);
  }
  
  .sv-body__page {
    background-color: var(--sjs-general-backcolor);
    box-shadow: var(--sjs-shadow-medium);
    border-radius: var(--sjs-corner-radius);
  }
  
  .sv-panel {
    background-color: var(--sjs-general-backcolor-dim-light);
    border-radius: var(--sjs-corner-radius);
  }
  
  // Style for the survey questions
  .sv-question {
    background-color: var(--sjs-general-backcolor);
    border-radius: var(--sjs-corner-radius);
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: var(--sjs-shadow-small);
    
    .sv-question__title {
      color: var(--sjs-primary-backcolor);
      font-weight: 600;
    }
  }

  // Style for the survey progress bar
  .sv-progress {
    background-color: var(--sjs-general-backcolor-dim-light);
    
    .sv-progress__bar {
      background-color: var(--sjs-primary-backcolor);
    }
  }
}

// Apply theme to SurveyResponseProcessor
.survey-response-processor {
  background-color: var(--sjs-general-backcolor);
  
  .ant-card {
    border-radius: var(--sjs-corner-radius);
    box-shadow: var(--sjs-shadow-medium);
  }
  
  .ant-card-head {
    background-color: var(--sjs-primary-backcolor);
    color: var(--sjs-primary-forecolor);
    border-top-left-radius: var(--sjs-corner-radius);
    border-top-right-radius: var(--sjs-corner-radius);
  }
  
  .ant-btn-primary {
    background-color: var(--sjs-primary-backcolor);
    border-color: var(--sjs-primary-backcolor);
    
    &:hover {
      background-color: var(--sjs-primary-backcolor-dark);
      border-color: var(--sjs-primary-backcolor-dark);
    }
  }
  
  .ant-collapse {
    border-radius: var(--sjs-corner-radius);
  }
  
  .ant-collapse-header {
    background-color: var(--sjs-general-backcolor-dim-light);
  }
}

// Apply theme to AddressMapConfirmation
.address-map-confirmation {
  .ant-alert {
    border-radius: var(--sjs-corner-radius);
  }
  
  .map-container {
    border-radius: var(--sjs-corner-radius);
    overflow: hidden;
    box-shadow: var(--sjs-shadow-small);
  }
} 