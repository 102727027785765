.collapsibleRowBorder {
  border: 1px solid var(--alto);
}

.collapsibleRowPadding {
  padding: 12px;
}

.collapsibleRowIndent {
  padding-left: 28px;
}

.rooms {
  :global {
    li:not(:last-child) {
      border-bottom: 1px solid var(--alto);
    }
  }
}