.keylist-input {
  background-color: white;
  border: 1px solid var(--alto);
  padding: 24px;

  .text {
    color: var(--mine-shaft);
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }

  .sub-text {
    font-size: 12px;
    color: var(--gray);
  }
}
