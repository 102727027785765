.next-room-info {
  background: white;
}

.next-room-info .list-item-header {
  color: var(--scorpion);
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  padding-left: 22px;
  padding-top: 15px;
}

.next-room-info .list-item-header.node-list-header {
  padding-top: 10px;
}

.next-room-info .list-item-text {
  color: var(--gray);
  font-size: 16px;
  margin-top: 0;
  padding-left: 22px;
}

.next-room-info .list-item-text .node-slot-text {
  margin-bottom: 0;
}

.next-room-info .list-item-text .node-slot-text:last-child {
  padding-bottom: 20px;
}

.next-room-info .divider {
  margin: 15px 0;
}
