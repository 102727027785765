.precool-now-confirm {
    &-modal {
        .ant-modal-body {
          text-align: center;
        }
        &-icon {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 50%;
          background: #E6F1F8;
          max-width: fit-content;
          margin: 0 auto 10px;
          .svg-icon svg path {
            fill: #006EB7;
          }
        }
    }
    &-actions {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        &-btn {
          width: 100%;
          border-radius: 4px;
          border: none;
          font-weight: 600;
          line-height: 22px;
          padding: 4px 12px;
          .svg-icon {
            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }
        &-cancel {
            border: 1px solid #ccd4dc;
            background: #fff;
            min-height: 50px;
          }
        &-confirm {
            background: #006EB7;
            min-height: 50px;
            color: #fff;
        }
    }
}