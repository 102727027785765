@import "styles/mixinStyles";

.room-tabs {
  margin: 0 -1em;
  .ant-tabs-nav-wrap {
    background-color: #e6eaee;
    color: #000000;
    .ant-tabs-tab {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .ant-tabs-tab-btn {
      color: #000000 !important;
    }
    .ant-tabs-ink-bar {
      background: #ff8200 !important;
    }
  }
  .room-content {
    margin: auto 20px;
    .task-card {
      .taskTypeIcon {
        span {
          border-radius: 50%;
          padding: 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        .sensorIcon {
          background-color: #fff3e6;
        }
        .thermostatIcon {
          background-color: #ffeeeb;
        }
        .airPumpIcon {
          background-color: #fff0f6;
        }
        .voltMeterIcon {
          background-color: #eff9ee;
        }
      }
      .title {
        line-height: 38px;
        margin: auto 0;
      }
      .icon {
        margin: auto 0;
        .taskStatus {
          vertical-align: middle !important;
          font-size: 20px;
        }
        .rightIcon {
          height: 28px;
          color: #8d8d8d;
        }
      }
    }
  }
}

.task-card {
  margin-bottom: 10px;
}
.room-content {
  .ant-collapse-item {
    &-disabled {
      .ant-collapse-header {
        &-text {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .ant-collapse-header {
      align-items: center;
    }
  }

  .disabled {
    .ant-collapse-header {
      padding: 12px 0 !important;
    }
  }
}
