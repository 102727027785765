.current-room-info {
  padding: 15px 0 10px 25px;
}

.current-room-info p {
  margin-bottom: 0;
}

.current-room-info .list-item-header {
  color: var(--scorpion);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.current-room-info .list-item-text {
  color: var(--gray);
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 16px;
  margin-top: -5px;
}

.current-room-info .list-item-category-text {
  color: var(--gray);
  font-size: 16px;
  font-weight: normal;
}
