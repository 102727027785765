.headerBgColor {
  background-color: white;
  border-bottom: 1px solid var(--alto);
}

.logo {
  padding: 0.5em;
  margin-right: 1em;
  height: 60px;
  width: auto;
}

.accountName {
  margin-left: auto;
  margin-right: 2em;
  display: grid;
  align-self: flex-end;

  span {
    height: 22px;
  }
}

.mobileMenuHeaderText {
  width: calc(100% - 35px);
  display: inline-block;
}

.sideMenuCloseBtn {
  position: absolute;
  right: 10px;
}

.mobileMenu {
  :global {
    .ant-drawer-body {
      padding: 0;
    }

    .ant-menu-item {
      height: 30px;
      line-height: 30px;

      a {
        color: var(--deep-cerulean);
      }
    }

    .ant-drawer-title {
      font-size: 15px;
    }
  }
}

:global {
  .ant-menu-horizontal {
    border: unset !important;
  }
}
