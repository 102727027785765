@import "styles/variables";

.comfort-plus-layout .ant-layout-content {
  background-color: white;
}

.info-text {
  color: #5f5f5f;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 22px;
}

.stop-cooling-btn {
  background-color: #ff8200;
  color: #fff;
  &:hover,
  &:active {
    color: #ff8200;
    border-color: #ff8200;
    .svg-icon svg path {
      fill: #ff8200;
    }
  }
}

.main-container {
  height: 100%;
}

.precool-comfortPlus {
  &-content {
    min-height: calc(100vh - 120px);
    position: relative;
  }
}
