@import "styles/mixinStyles";

.claim-key-button {
  background-color: #e1f5fe;
  border: none;
  &.un-claim {
    background-color: #ffeeeb;
    color: #e8472e !important;
  }
}

.svg-icon-size {
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #f4b14a;
    }
  }
}
