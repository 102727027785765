.gateway-status-indicator {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.more-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
