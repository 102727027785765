.gateway-stats .content-right-section {
  background-color: white;
  margin-left: 20px;
  margin-top: 70px;

  .content-header {
    color: var(--mine-shaft);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    padding: 15px 0 0 15px;
  }

  .ant-divider {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
    padding: 5px 15px 15px 15px;
  }
}

.gateways-creation-mode-button {
  background-color: var(--deep-cerulean)
}
