@import "styles/mixinStyles";

.category-list {
  background: white;
  border: 1px solid var(--alto);
  border-right: none;
  height: 100%;

  .category {
    border: none;
    box-shadow: none;
    height: 40px;
    padding-left: 48px;
    text-align: left;
    width: 100%;

    &.is-selected {
      color: var(--deep-cerulean);
      background-color: var(--lily-white);
      border-right: 3px solid var(--deep-cerulean);
    }
  }
}
