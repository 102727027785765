.action-download-image {
  color: var(--deep-cerulean) !important;
  padding-left: 0;
}

.action-troubleshoot {
  color: var(--west-side) !important;
}

.image-available {
  font-size: 10px;
  font-weight: 400;
}
