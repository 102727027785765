.precool-schedule-modal {
  .ant-drawer-content-wrapper {
    height: 100% !important;
  }
  .reservation {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #ccd4dc;
    background: #f2f4f6;
    margin-bottom: 16px;
  }
  &-action {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 16px;
    padding: 0 24px;
    .ant-btn {
      width: 100%;
      min-height: 50px;
    }
  }

  .ant-form-item-required {
    &::before {
      content: none !important;
    }
  }
}

.ant-select-dropdown {
  // hard to lie on Drawer
  z-index: 2147484002;
}

.precool-schedule-item {
  &-content {
    display: flex;
    padding: 12px 12px 0px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    &-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;
      align-self: stretch;
      border-bottom: 1px solid #e6eaee;
      padding-bottom: 12px;
    }
  }
  &-actions {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    &-btn {
      width: 100%;
      border-radius: 4px;
      border: none;
      font-weight: 600;
      line-height: 22px;
      padding: 4px 12px;
      .svg-icon {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    &-edit {
      color: #006eb7;
      background-color: #e6f1f8;
      .svg-icon svg path {
        fill: #006eb7;
      }
    }

    &-delete {
      color: #e8472e;
      background-color: #ffeeeb;
      .svg-icon svg path {
        fill: #e8472e;
      }

      &-modal {
        .ant-modal-body {
          text-align: center;
        }
        &-icon {
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 50%;
          background: #ffeeeb;
          max-width: fit-content;
          margin: 0 auto 10px;
          .svg-icon svg path {
            fill: #e8472e;
          }
        }
      }
    }
    &-cancel {
      border: 1px solid #ccd4dc;
      background: #fff;
      min-height: 50px;
    }
    &-confirm-del {
      background: #e8472e;
      min-height: 50px;
      color: #fff;
    }
  }
}

.days-of-the-week {
  &-item {
    display: flex;
    height: 40px;
    padding: 7px 19px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #ccd4dc;
    &--active {
      border-radius: 4px;
      border: 1px solid #006eb7;
      background: #e6f1f8;
    }
  }
}
