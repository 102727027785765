.pre-cool-overall-status-detail {
  .ant-modal-body {
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  &-title {
    color: #002952;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    align-self: stretch;

    &-item {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 4px;
      &-title {
        color: #5f5f5f;
        font-size: 12px;
        font-weight: 400;
      }

      &-chip {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px; /* 140% */
        &.cooled {
          background: #e6f1f8;
          color: #006eb7;
        }
        &.cooling {
          background: #fef7ed;
          color: #f4b14a;
        }
      }

      &-icons {
        display: flex;
        align-items: center;
        align-self: stretch;
        gap: 4px;
      }

      &-content {
        margin-left: auto;
        margin-right: 0;
        color: #313131;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
