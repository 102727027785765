@import "styles/mixinStyles";

.location-header {
  margin-bottom: 1em;

  .header-title {
    display: inline;
    margin-right: 20px;
  }

  .header-description {
    display: inline;
  }
}

.add-location {
  @include default-button;
  margin-bottom: 1em;

  .header-title {
    display: inline;
    margin-right: 20px;
  }

  .header-description {
    display: inline;
  }
}
