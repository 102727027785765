.pagination-v3 {
  .ant-pagination-item {
    border-radius: 6px;
    border: none;
    &-active {
      background: var(--deep-cerulean);
      a {
        color: var(--white);
      }
    }
  }
}
