@import "styles/mixinStyles";

.readonly-text {
  padding: 5px;
  display: inline-block;
  background-color: var(--mercury);
  width: calc(100% - 50px);
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.primary-node-dropdown {
  min-width: 250px;
  max-width: 100%;
}

.key-details-header {
  .title {
    display: flex;
    align-items: center;

    .ant-typography {
      display: flex;
      font-weight: 400;
      color: var(--mine-shaft);
      margin-bottom: unset;
    }

    .anticon {
      margin-left: 12px;
      font-size: 21px;

      &.anticon-clock-circle {
        color: var(--west-side);
      }

      &.anticon-check-circle {
        color: var(--lima);
      }

      &.icon-not-started {
        width: 21px;
        height: 21px;
        border: 2px solid var(--gray);
        border-radius: 50%;
      }
    }
  }

  .blocks {
    .category,
    .progress {
      .label {
        line-height: 20px;
        letter-spacing: 0.05em;
      }

      .content {
        line-height: 24px;
      }

      .progress-bar {
        width: 160px;
      }
    }

    .category {
       min-width: 250px;
    }

    .progress {
      min-width: 250px;
      .ant-progress-inner {
        border-radius: 8px;

        .ant-progress-bg {
          background-color: var(--deep-cerulean);
          height: 8px;
        }
      }
    }

    .btn-reprint {
      @include default-button;
    }
  }
}

.key-details-room {
  color: var(--scorpion);

  .room-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    & > div {
      display: flex;
      align-items: center;
    }

    .room-name {
      font-weight: normal;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .slots {
    padding: 0 5px;
  }
}

.actions-right-mobile {
  position: absolute;
  right: 0;
  top: 5px;
}

.checked-test-run-button {
  width: 40px;
  height: 40px;
  border: 0;
  padding: 0;
}