.location-contructor {
  &-card {
    .ant-card-head,
    .ant-card-body {
      &::before,
      &::after {
        content: unset;
      }
    }
    .ant-card-head {
      background: #f2f4f6;
      .ant-card-head-title {
        max-width: 180px;
        color: var(--deep-cerulean);
        font-weight: bold;
      }
    }
    .ant-card-body {
      max-width: 100%;
      padding: 16px 24px;
    }
  }
}
