@import "styles/mixinStyles";

.infrastructure-type {
  flex: 1 1 auto;

  .infrastructure-type-selector {
    width: 100%;

    &.open-energy-setting {
      width: 90%;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;

    .infrastructure-type-selector {
      width: 100% !important;
    }
  }
}

.energy-setting {
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}
