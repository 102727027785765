@import "styles/mixinStyles";

.categories-and-keys-creation {
  .create-category-section {
    background-color: white;
    border: 1px solid var(--alto);
    min-height: 400px;

    .left-section {
      max-height: 65vh;
      overflow-y: auto;
    }

    .right-section {
      padding: 25px;
      max-height: 65vh;
      overflow-y: auto;
      border-left: 1px solid var(--alto);
    }
  }

  .footer-section {
    background-color: white;
    border: 1px solid var(--alto);
    border-top: unset;
    height: 70px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;

    .assign-keys {
      @include default-button;
      color: white;
      margin-right: 15px;
    }
  }
}

.confirm-modal {
  @include default-modal;

  .ant-modal-confirm-title {
    font-weight: 600;
    line-height: 24px;
    color: var(--mine-shaft);
  }

  .ant-modal-confirm-content {
    color: var(--scorpion);
    line-height: 22px;
  }
}
