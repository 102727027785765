.keys-stats {
  background-color: white;
  padding: 15px 15px;
}

.keys-stats b {
  color: var(--mine-shaft);
  font-size: 14px;
  font-weight: 600;
}

.keys-stats p {
  color: var(--mine-shaft);
  font-size: 14px;
}
