.input-node-mac-id {
  h4 {
    display: none;
  }
}

.internal-error {
  color: var(--volcano);
  font-size: 15px;
  font-weight: normal;
  padding-left: 10px;
}

.modal-node-life-cycle {
  .modal-button {
    text-align: right;
    padding-top: 10px;
  }
}