.progress-component {
  background-color: white;
  padding: 15px 15px;
}

.progress-component b {
  color: var(--mine-shaft);
  font-size: 14px;
  font-weight: 600;
}

.progress-component p {
  color: var(--mine-shaft);
  font-size: 14px;
}
