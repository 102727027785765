
.overall-status-sidebar-container {
  background-color: white;
  border: 1px solid var(--alto);
  margin-left: 31px;
  margin-top: 16px;
  padding-bottom: 11px;
  display: flex;
  flex-direction: column;
}

.overall-status-sidebar-header {
  padding-left: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.sidebar-block-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.overall-status-header-divider {
  width: 2px;
  color: var(--alto);
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.overall-status-section-loading {
  margin-left: auto;
  margin-right: auto;
  padding-top: 16px;
}

.overall-status-section-no-data-text {
  padding-top: 16px;
  padding-left: 14px;
}
