@import "styles/mixinStyles";

.mapped-nodes-table {
  .ant-table-tbody {
    .ant-table-cell {
      justify-content: center;

      .node-id-block {
        display: flex;
        align-items: center;

        .node-id {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          direction: rtl;
          text-align: left;

          @include media-breakpoint-down(xl) {
            width: 60px;
          }
        }

        svg {
          margin-left: unset;
        }
      }

      .last-seen {
        .status {
          width: 12px;
          height: 12px;
          vertical-align: middle;
          border-radius: 50%;
          margin-right: 5px;
          background: var(--razzmatazz);

          &.online {
            background: var(--persian-green);
          }
        }
      }
    }
  }
}

.node-mapped-grid {
  .node-mapped:first-child {
    border: 1px solid var(--silver);
  }

  .node-mapped {
    border: 1px solid var(--silver);
    border-top: unset;
    padding-top: 16px;

    .collapse-header {
      .data-section {
        margin: unset;
      }
    }

    .actions {
      svg {
        margin-right: 15px;
      }
    }

    .data-section {
      margin: 10px;

      p {
        margin-bottom: unset;
      }

      .title {
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 12px;
      }
    }

    .node-status-block {
      display: flex;

      .node-status {
        align-self: center;
        border-radius: 5px;
        text-align: center;
        padding: 5px 5px;
        font-weight: bold;

        span {
          text-transform: uppercase;
          font-size: 12px;
          color: white;
        }

        &.online {
          background-color: var(--persian-green);
        }

        &.offline {
          background-color: var(--razzmatazz);
        }
      }
    }

    .collapse {
      width: 100%;

      .collapse-icon {
        margin-top: 8px;
      }

      .ant-collapse-content-box {
        padding: unset;
        padding-top: 4px;
      }

      .ant-collapse-item {
        border-bottom: unset;
      }
    }
  }
}
