.upcoming-pre-cool {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 20px 0;
  border-radius: 4px;
  background: #f2f8fb;
  color: #484848;

  &-title {
    h5 {
      color: #484848;
    }
  }
}

.upcoming-pre-cool-content {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  .upcoming-pre-cool-modal & {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }
}

.upcoming-pre-cool-modal {
  .ant-modal-header {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    & .ant-modal-title {
      color: #002952;
    }
  }
  .ant-modal-body {
    padding: 1rem;
  }
}
