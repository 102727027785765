@import "styles/variables";
@import "styles/global";

.unit-select {
  border-radius: 3px;
  transition: .3s;

  .unit-select__label {
    color: $dark-blue !important;
    margin-right: -20px;

    @include media-breakpoint-down(md) {
      margin-right: -15px;
    }
  }
  
  .unit-select__select {
    .ant-select-selector {
      padding: 0 20px !important;

      @include media-breakpoint-down(md) {
        padding: 0 15px !important;
      }
    }

    .ant-select-selection-item {
      font-weight: 700;
      color: $dark-blue !important;
    }

    .ant-select-arrow {
      right: 20px;

      @include media-breakpoint-down(md) {
        right: 15px;
      }
    }
  }
}

.unit-select__dropdown {
  padding: 0;
  .ant-select-item {
    margin: 0 10px;
    padding: 14px 13px;
    background-color: transparent;

    &:not(:last-child) {
      box-shadow: inset 0px -1px 0px #E1E6EC;
    }
  }
}
