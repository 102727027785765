.filterToggleBtn {
  width: 100px;
}

.filterToggleBtnMobile {
  width: 30px;
  padding-left: 0;
  padding-right: 0;
}

.filterMenuHeader {
  color: var(--scorpion);
  font-weight: 600;
  font-size: 16px;
  padding: 5px 12px 3px;
  :global {
    .anticon-filter {
      margin-right: 8px;
    }
  }
}

.filterOptionLabelCount {
  padding-left: 8px;
  color: var(--gray);
}

.filterDropdownMenu {
  max-height: 300px;
  overflow-y: auto;
  background-color: var(--white);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 4px 0;
}

.filterMenuItem {
  line-height: 22px !important;
  height: 32px !important;
  padding: 5px 0px !important;
  margin-bottom: 0 !important;
}

.filterMenuGroup {
  padding: 5px;
  min-width: 150px;
}

.filterMenuGroupName {
  color: var(--scorpion);
  font-weight: 600;
  padding: 15px 10px;
}

.filterInputValueInput {
  width: 70px;
  margin-right: 5px;
}

.filterSelect {
  width: 70px;
}

.clearButton {
  width: 51px;
  height: 24px;
  padding: 1px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.applyButton {
  width: 51px;
  height: 24px;
  padding: 1px 8px;
  border-radius: 2px;
  background-color: var(--daybreak-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  margin-left: 10px;
  border: none;
}

.applyButton:hover {
  background-color: var(--daybreak-blue);
  color: var(--white);
}

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  right: 0;
  padding-right: 10px;
  bottom: 18px;
  float: right;
  margin: 10px 5px;
}

.filterIcon {
  font-size: 16px;
}

.filterGroupContent {
  height: 130px;
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
}

.filterDropdownMenuDesktop {
  overflow-y: hidden !important;
}