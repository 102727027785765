$border: 4px;

.onboarding {
  position: relative;
  width: 100%;
  height: 100vh;
  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
  }
  &-action {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -8px;
    .ant-btn {
      width: 100%;
      min-height: 50px;
    }
  }
  &-content {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    height: 100%;
  }
  .ant-drawer {
    &-content-wrapper {
      width: 100% !important;
    }

    &-header {
      border-bottom: none;
      padding-top: 40px;
    }
    &.step-1 {
      .ant-drawer-content {
        background-image: url("/images/onboarding/background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
      }

      .logo-brand {
        position: relative;
        width: 200px;
        height: 200px;
        &-blur {
          position: absolute;
          width: 200px;
          height: 200px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          opacity: 0.8;
          background: #fff;
          filter: blur(21.64285659790039px);
          z-index: 1;
        }
        &-live {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background: linear-gradient(225deg, #1a7dbe 0%, #3aa9dc 31.33%, #fbbc04 100%);
          z-index: 2;
        }
        &-content {
          overflow: hidden;
          width: 150px - $border;
          height: 150px - $border;
          border-radius: 50%;
          background: #fff;
          padding: 10px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
