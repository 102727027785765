.gateway-name {
  color: var(--scorpion);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 32px;
}

.gateway-error {
  color: var(--cinabar);
  font-size: 10px;
  font-weight: 400;
}

.gateway-name-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
