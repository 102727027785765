@import "styles/variables";

.logo {
  padding: 0.5em;
  margin-right: 1em;
  height: 60px;
  width: auto;
  fill: white;
}

.logo-container {
  background-color: #002952;
}
