@import "styles/mixinStyles";

p {
  margin-bottom: 5px;
}

.add-button {
  @include default-button;
}

.input-field {
  margin-bottom: 25px;

  &.with-action {
    align-items: center;
  
    .action-icon {
      padding-left: 30px;
      display: flex;

      svg {
        font-size: 18px;
        color: var(--deep-cerulean);
        cursor: pointer;
      }

      &.disable {
        svg {
          color: var(--gray);
          cursor: no-drop;
        }
      }

      &.hide {
        display: none;
      }
    }
  }

  &.with-preview {
    margin-bottom: unset;
  }
}

.room-detail {
  margin-top: 24px;
  border: 1px solid var(--alto);
  padding: 24px 16px;
}

.hint {
  color: var(--gray);
}

.required-text {
  color: red;
}
