@import "styles/global";

.contractor-infrastructure {
  .title {
    font-weight: bold;
  }
  &-type {
    font-size: 12px;
  }
  &-button {
    width: 100%;
    button {
      width: 100%;
    }
  }

  .stopwatch {
    right: 0;
  }

  @include media-breakpoint-down(375) {
    .custom-col {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .custom-row {
      justify-content: flex-end;
    }
  }
}
