/* color */

.text-warning {
  color: var(--buttercup) !important;
}

.text-primary {
  color: var(--deep-cerulean) !important;
}

.text-danger {
  color: var(--burnt-sienna) !important;
}

.text-scorpion {
  color: var(--scorpion) !important;
}

.text-gray {
  color: var(--gray) !important;
}

.text-green {
  color: var(--lima) !important;
}

.text-blue {
  color: var(--semi-blue) !important;
}

.text-white {
  color: var(--white) !important;
}

.text-black {
  color: var(--black) !important;
}

.text-volcano {
  color: var(--volcano) !important;
}

.text-dark-orange {
  color: var(--dark-orange-100);
}

.fill-white {
  fill: var(--white);
  svg path {
    fill: var(--white);
  }
}

/* background */

.bg-primary {
  background-color: var(--deep-cerulean) !important;
}

.bg-white {
  background-color: white !important;
}

.bg-light-gray {
  background-color: var(--mercury) !important;
}

.bg-success {
  background-color: var(--lima);
}

.bg-warning {
  background-color: var(--calendula-gold);
}

.bg-light-warning {
  background-color: rgba($light-orange, 0.1);
}

.bg-error {
  background-color: var(--error);
}

.bg-default {
  background-color: var(--mine-shaft);
}

.bg-lily-white {
  background-color: var(--lily-white);
}

.bg-dark-orange {
  background-color: var(--dark-orange);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-transparent {
  background: transparent !important;
}

.hover\:bg-white:hover {
  background-color: white !important;
}

/* font sizes */

.fs-xs {
  font-size: 10px !important;
}

.fs-sm {
  font-size: 12px !important;
}

.fs-m {
  font-size: 14px !important;
}

.fs-l {
  font-size: 16px !important;
}

.fs-xl {
  font-size: 20px !important;
}

.fs-xxl {
  font-size: 24px !important;
}

.fs-h1 {
  font-size: 36px !important;
}

.fs-h2 {
  font-size: 30px !important;
}

.fs-h3 {
  font-size: 26px !important;
}

.fs-h4 {
  font-size: 22px !important;
}

.fs-body-18 {
  font-size: 18px !important;
}

// borders

.border {
  border-style: solid;
  border-width: 1px;
}

.border-warning {
  border-color: var(--west-side);
}

.border-semi-blue {
  border-color: var(--semi-blue);
}

.border-light-blue {
  border-color: var(--light-blue);
}

.border-light-gray {
  border-color: var(--silver);
}

.border-dark-blue {
  border-color: #ccd4dc;
}

/* Border radius */

.radius-xs {
  border-radius: 5px;
}

.radius-s {
  border-radius: 10px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-30 {
  border-radius: 30px;
}

.radius-t-12 {
  border-radius: 12px 12px 0px 0px;
}

.radius-b-12 {
  border-radius: 0px 0px 12px 12px;
}

/*
  Size standard: xs = 5px, sm = 10px, m = 15px, l = 20px, xl = 25px
  m = margin
  p = padding
  t = top, b = bottom, l = left, r = right, x = left & right, y = top & bottom, none = 0
*/
/* spacing */
.w-52r {
  width: 13rem !important;
}

.w-36r {
  width: 9rem !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-4r {
  height: 4rem !important;
}

.h-10r {
  height: 10rem !important;
}

.h-36r {
  height: 36rem !important;
}

.h-20vw {
  height: 20vw !important;
}

.h-75vw {
  height: 75vw !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-none {
  margin: 0 !important;
}

.mt-none,
.my-none {
  margin-top: 0 !important;
}

.mr-none,
.mx-none {
  margin-right: 0 !important;
}

.mb-none,
.my-none {
  margin-bottom: 0 !important;
}

.ml-none,
.mx-none {
  margin-left: 0 !important;
}

.m-xs {
  margin: 5px !important;
}

.mt-xs,
.my-xs {
  margin-top: 5px !important;
}

.mr-xs,
.mx-xs {
  margin-right: 5px !important;
}

.mb-xs,
.my-xs {
  margin-bottom: 5px !important;
}

.ml-xs,
.mx-xs {
  margin-left: 5px !important;
}

.m-s {
  margin: 10px !important;
}

.mt-s,
.my-s {
  margin-top: 10px !important;
}

.mr-s,
.mx-s {
  margin-right: 10px !important;
}

.mb-s,
.my-s {
  margin-bottom: 10px !important;
}

.ml-s,
.mx-s {
  margin-left: 10px !important;
}

.m-m {
  margin: 15px !important;
}

.mt-m,
.my-m {
  margin-top: 15px !important;
}

.mr-m,
.mx-m {
  margin-right: 15px !important;
}

.mb-m,
.my-m {
  margin-bottom: 15px !important;
}

.ml-m,
.mx-m {
  margin-left: 15px !important;
}

.m-l {
  margin: 20px !important;
}

.mt-l,
.my-l {
  margin-top: 20px !important;
}

.mr-l,
.mx-l {
  margin-right: 20px !important;
}

.mb-l,
.my-l {
  margin-bottom: 20px !important;
}

.ml-l,
.mx-l {
  margin-left: 20px !important;
}

.m-xl {
  margin: 25px !important;
}

.mt-xl,
.my-xl {
  margin-top: 25px !important;
}

.mr-xl,
.mx-xl {
  margin-right: 25px !important;
}

.mb-xl,
.my-xl {
  margin-bottom: 25px !important;
}

.ml-xl,
.mx-xl {
  margin-left: 25px !important;
}

.mt-32,
.my-32 {
  margin-top: 32px !important;
}

.p-none {
  padding: 0 !important;
}

.pt-none,
.py-none {
  padding-top: 0 !important;
}

.pr-none,
.px-none {
  padding-right: 0 !important;
}

.pb-none,
.py-none {
  padding-bottom: 0 !important;
}

.pl-none,
.px-none {
  padding-left: 0 !important;
}

.p-xs {
  padding: 5px !important;
}

.pt-xs,
.py-xs {
  padding-top: 5px !important;
}

.pr-xs,
.px-xs {
  padding-right: 5px !important;
}

.pb-xs,
.py-xs {
  padding-bottom: 5px !important;
}

.pl-xs,
.px-xs {
  padding-left: 5px !important;
}

.p-s {
  padding: 10px !important;
}

.pt-s,
.py-s {
  padding-top: 10px !important;
}

.pr-s,
.px-s {
  padding-right: 10px !important;
}

.pb-s,
.py-s {
  padding-bottom: 10px !important;
}

.pl-s,
.px-s {
  padding-left: 10px !important;
}

.p-m {
  padding: 15px !important;
}

.pt-m,
.py-m {
  padding-top: 15px !important;
}

.pr-m,
.px-m {
  padding-right: 15px !important;
}

.pb-m,
.py-m {
  padding-bottom: 15px !important;
}

.pl-m,
.px-m {
  padding-left: 15px !important;
}

.p-l {
  padding: 20px !important;
}

.pt-l,
.py-l {
  padding-top: 20px !important;
}

.pr-l,
.px-l {
  padding-right: 20px !important;
}

.pb-l,
.py-l {
  padding-bottom: 20px !important;
}

.pl-l,
.px-l {
  padding-left: 20px !important;
}

.p-xl {
  padding: 25px !important;
}

.pt-xl,
.py-xl {
  padding-top: 25px !important;
}

.pr-xl,
.px-xl {
  padding-right: 25px !important;
}

.pb-xl,
.py-xl {
  padding-bottom: 25px !important;
}

.pl-xl,
.px-xl {
  padding-left: 25px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

/* alignment */

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.vertical-align-center {
  vertical-align: middle !important;
}

/* display */

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column,
.flex-row {
  -webkit-box-direction: normal !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse,
.flex-row-reverse {
  -webkit-box-direction: reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-none {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-xs {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-none {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-xs {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-heading {
  font-family: $heading-font !important;
}

.lh-s {
  line-height: 1em !important;
}

.lh-l {
  line-height: 32px;
}

.button-mobile {
  font-size: 12px;
  height: 24px;
  padding: 0 7px;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-h4 {
  line-height: 28px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-success {
  color: var(--lima);
}

.text-warning {
  color: var(--west-side);
}

.text-error {
  color: var(--error);
}

.gap-s {
  gap: 10px;
}

/** order */
@for $i from 1 through 12 {
  .order-#{$i} {
    order: #{$i};
  }
}

/** position */
.position-absolute {
  position: absolute;
}

.list-style-none {
  list-style: none;
}

.direction-rtl {
  direction: rtl;
}

.direction-ltr {
  direction: ltr;
}

.overflow-hidden {
  overflow: hidden !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.cursor-default {
  cursor: default !important;
}

.word-wrap-break {
  word-wrap: break-word;
}

.layout-contractor {
  background-color: #fff;
}

.layout-public {
  min-height: calc(100vh - 120px);
  padding-bottom: 100px;
}

.layout-onloading {
  background-color: #fff;
  .ant-layout-content {
    padding: 0;
  }
}

.ant-layout-content {
  padding: 20px;
}
