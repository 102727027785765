.current-room-node-slots-list {
  padding: 10px 0 40px 25px;
}

.current-room-node-slots-list p {
  margin-bottom: 5px;
}

.current-room-node-slots-list .node-code-text {
  color: var(--gray);
  font-size: 20px;
}

.current-room-node-slots-list .slot-name-text {
  color: var(--gray);
  font-size: 16px;
}

.current-room-node-slots-list .anticon {
  margin-left: 10px;
}
