@import "styles/mixinStyles";

.upload-firmware-modal {
  @include default-modal;

  .ant-modal-body {
    padding-left: 80px;
    padding-right: 80px; 
  }

  .ant-upload-list-item:not(.ant-upload-list-item-error) {
    .ant-upload-list-item-name {
      color: var(--daybreak-blue);
    }
  }

  .version-number-input {
    width: 100%;
  }
}
