@import "styles/mixinStyles";

.extended-mode {
  border: 1px solid var(--mercury);
}

.slot {
  padding: 10px;
  height: 100%;
  .slot-name {
    margin-bottom: 10px;
    &:first-child {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .node-type,
  .node-mac-id {
    font-size: 14px;
    line-height: 22px;
    margin: unset;
  }

  .map,
  .unmap {
    margin-top: 10px;
  }

  .unmap {
    border: unset;
    background: unset;
    color: var(--burnt-sienna);
    cursor: pointer;
    outline: none;
  }
}

.node-status {
  background-color: var(--gray);
  display: inline-table;
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
  text-transform: uppercase;
}

.online-status {
  background-color: var(--lima);
}

.offline-status {
  background-color: var(--burnt-sienna);
}

.not-map-status {
  background-color: var(--buttercup);
}

.booted-status {
  background-color: var(--deep-cerulean);
}

.confirm-unmap-popup {
  .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--mine-shaft);
  }

  .ant-modal-confirm-content {
    width: 280px;
    font-size: 14px;
    line-height: 22px;
    color: var(--scorpion);
  }
  .question-icon.anticon-question-circle {
    font-size: 21px;
    color: var(--buttercup);
  }
  button {
    padding: 3px 16px;
    box-sizing: unset;

    &.btn-umnap-node {
      @include default-button;
    }
  }
}

.map-node-modal {
  @include default-modal;

  .slot-name {
    font-size: 30px;
    line-height: 38px;
    color: var(--scorpion);
  }

  .node-type {
    font-size: 14px;
    line-height: 22px;
    color: var(--scorpion);
  }
}

.custom-collapsible {
  border: none !important;

  .ant-collapse-header {
    padding: 0 !important;

    & > div {
      padding: 12px 16px;
    }

    & > div:nth-child(2) {
      padding-left: 40px;
    }

    .anticon {
      margin-top: 5px;
    }
  }
}

.slot-render-action-btn {
  display: grid;
  grid-template-columns: auto auto;
  align-items: end;
  column-gap: 10px;
}