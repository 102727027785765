.current-room-text {
  color: var(--scorpion);
  font-size: 30px;
  margin-bottom: 0;
}

.current-slot-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--scorpion);
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}

.ant-tabs-top .ant-tabs-nav {
  margin-bottom: 25px;
}

.ant-tabs-tabpane h4 {
  color: var(--mine-shaft);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.ant-tabs-tabpane #mapping-input {
  margin-bottom: 15px;
  width: 255px;
}

.scan-node-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 100px);
  justify-content: space-between;
}

.scan-node-wrapper .scan-action {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.camera-view {
  width: 100%;
  max-width: 500px;
}

#previous-button {
  display: none;
}

.ant-tabs-tabpane .internal-error {
  color: var(--volcano);
  font-size: 16px;
  font-weight: normal;
  width: 90%;
}
