.survey-response-processor {
  width: 100%;
  max-width: 100%;
  
  .ant-card {
    width: 100%;
    margin-bottom: 16px;
    
    .ant-card-head {
      padding: 16px;
    }
    
    .ant-card-body {
      padding: 16px;
    }
  }
  
  .ant-collapse {
    width: 100%;
    margin-top: 16px;
    
    .ant-collapse-item {
      margin-bottom: 8px;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      
      .ant-collapse-header {
        font-weight: 500;
        padding: 12px 16px;
        background-color: #fafafa;
      }
      
      .ant-collapse-content {
        border-top: 1px solid #f0f0f0;
        
        .ant-collapse-content-box {
          padding: 16px;
        }
      }
    }
  }
  
  .ant-descriptions {
    width: 100%;
    
    .ant-descriptions-item-label {
      width: 30%;
      font-weight: 500;
      background-color: #fafafa;
    }
    
    .ant-descriptions-item-content {
      width: 70%;
    }
  }
  
  .survey-summary-container {
    width: 100%;
    
    .ant-typography {
      margin-bottom: 8px;
    }
  }
  
  .map-confirmation-container {
    width: 100%;
    
    .ant-typography {
      margin-bottom: 8px;
    }
  }
  
  .action-buttons {
    margin-top: 24px;
    text-align: center;
    
    .ant-btn {
      min-width: 200px;
    }
  }
  
  .ant-alert {
    margin-bottom: 16px;
  }
  
  .ant-table-wrapper {
    width: 100%;
    
    .ant-table {
      width: 100%;
    }
  }
} 