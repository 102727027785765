@import "styles/mixinStyles";

.create-gateway-section {
  background-color: white;
  border: 1px solid var(--alto);
  min-height: 400px;

  .left-section {
    max-height: 65vh;
    overflow-y: auto;
  }

  .right-section {
    padding: 25px;
    max-height: 65vh;
    overflow-y: auto;
    border-left: 1px solid var(--alto);
  }
}

.footer-section {
  background-color: white;
  border: 1px solid var(--alto);
  border-top: unset;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .navigation-button-create-gateways {
    margin-right: 15px;
  }

  .navigation-button-edit-wifi-credentials {
    @include default-button;
    border-color: var(--alto);
    color: var(--scorpion);
    background-color: white;
    margin-left: 15px;
    margin-right: auto;
  }
}
