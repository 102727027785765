.setpointInlineInput {
  width: 75px;
}

.timepicker {
  width: 210px;
}

.switchField {
  height: 30px;
}

.timer {
  background-color: orange;
  padding: 10px 0;
  margin-bottom: 10px;
}