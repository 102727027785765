@import "./mixinStyles.scss";

.btn-popup-primary {
  @include default-button;
}

.ant-modal-title {
  font-weight: 600;
}

.ant-modal-header {
  padding-right: 45px;
}

.stacked-controls {
  &.left > * {
    margin-right: 15px;
  }

  &.right > * {
    margin-left: 15px;
  }
}

.main-container {
  padding: 0 1em;
}

.main-container-lg {
  padding: 0 4em;
}

.header-title-row {
  margin-bottom: 1em;
}

.confirm-modal {
  @include default-modal;

  .ant-modal-confirm-title {
    font-weight: 600;
    line-height: 24px;
    color: var(--mine-shaft);
  }

  .ant-modal-confirm-content {
    color: var(--scorpion);
    line-height: 22px;
  }
}

.header-breadcrumb {
  font-size: 10px;
  line-height: 14px;
  color: var(--gray);
  margin-bottom: 15px;

  span:last-child {
    color: var(--scorpion);
  }
}

.btn-icon-only {
  padding: 0;

  & > span {
    font-size: inherit;
  }
}

.m-table {
  .ant-table-thead {
    display: none;
  }

  .ant-table {
    background-color: transparent;

    table {
      border-spacing: 0 10px;

      .ant-table-tbody > tr > td {
        border: 1px solid var(--mercury);
        background-color: var(--white);
      }
    }
  }
}

.table-checkbox-with-expandable {
  .ant-table-selection-column {
    vertical-align: top;
  }

  .ant-table-row-level-1 .ant-checkbox-wrapper {
    position: absolute;
    right: -35px;
    z-index: 1000;
    top: 18px;
  }
}

.text-eclipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg-icon {
  vertical-align: middle;
  line-height: 1em;
}

.ant-btn[disabled],
.ant-dropdown-menu-item-disabled {
  .svg-icon {
    svg path {
      fill: var(--silver);
    }
  }
}

.ant-radio-button-wrapper-checked {
  .svg-icon {
    svg path {
      fill: var(--white);
    }
  }
}

.normalize-dropdown-menu-icons {
  .ant-dropdown-menu-item-icon {
    width: 30px;
    display: inline-block;
    text-align: center;
  }
}

.no-padding-collapsible-tab {
  .ant-collapse-content-box {
    padding: 0;
  }
}

.form-input-space-between {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
}

.vh-100-modal {
  .ant-modal-body {
    max-height: calc(100vh - 250px);
    overflow: auto;
  }
}

.no-padding-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.full-screen-modal {
  .ant-modal {
    padding: 0;
    margin: 0;
    max-width: 100%;
    height: 100vh;
    .ant-modal-content {
      height: 100%;
      .ant-modal-body {
        height: calc(100% - 55px);
        font-size: 0;
      }
    }
  }
}

.no-footer-modal {
  .ant-modal-footer {
    border: 0;
    padding: 0;
  }
}

.footer-left-modal {
  .ant-modal-footer {
    text-align: left;
    button {
      margin-left: 0;
    }
    button:not(:last-child) {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.prefixed-icon {
  width: 20px;
  margin-right: 8px;
}

.hide-expand-icon {
  .ant-table-row-expand-icon {
    visibility: hidden !important;
  }
}

.ant-table-thead th {
  font-weight: bold !important;
}

.dot-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.ant-drawer {
  z-index: $drawerZIndex;
}

.ant-picker-dropdown {
  z-index: $pickerDropdownZIndex;
}

@include media-breakpoint-down(md) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

.scroll-x {
  white-space: nowrap;
  overflow-x: scroll;
}

.blur-left-right {
  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgba(239, 242, 245, 1), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, rgba(239, 242, 245, 1), rgba(255, 255, 255, 0));
  }
}

.mini-csv-button-in-collapse {
  width: 85px;
  height: 26px;
  padding: 2px 0 !important;
  font-size: 13.5px;
  span:last-child {
    margin-left: 0 !important;
  }
  span:first-child {
    margin-right: 5px !important;
  }
}

.green-svg-icon {
  svg path {
    fill: var(--lima) !important;
  }
}

.red-svg-icon {
  svg path {
    fill: var(--burnt-sienna) !important;
  }
}

.white-svg-icon {
  svg path {
    fill: white !important;
  }
}

.border-none {
  border: none !important;
}

.ant-table-row:hover .action-column {
  visibility: visible;
}

.ant-table-row .action-column {
  visibility: hidden;
}

.required {
  position: relative;
  &::after {
    content: "*";
    color: #e8472e;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 2px;
  }
}
