.breadcrumbStep {
  display: inline-block;

  span {
    vertical-align: middle;
  }

  .badge {
    border: 1px solid;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px;

    &.badgeCurrent {
      background-color: var(--deep-cerulean);
      color: white;
      border-color: var(--deep-cerulean);
    }

    &.badgeCompleted {
      background-color: white;
      color: white;
      border-color: var(--deep-cerulean);
      :global {
        .anticon-check {
          color: var(--deep-cerulean);
          padding-bottom: 2.5px;
        }
      }
    }

    &.badgeIncomplete {
      background-color: white;
      color: var(--silver);
      border-color: var(--silver);
    }
  }

  .badgeText {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    display: inline-block;
    margin-left: 8px;

    &.badgeTextCurrent {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: var(--mine-shaft);
    }

    &.badgeTextCompleted {
      color: var(--scorpion);
    }

    &.badgeTextIncomplete {
      color: var(--gray);
    }
  }
}
