#countdown-text {
  bottom: 75px;
  margin-bottom: 0;
  position: absolute;
  right: 30px;
}

#continue-scanning {
  position: absolute;
  bottom: 25px;
  right: 30px;
}

#label-printing-wrapper {
  margin-bottom: 200px;
}
