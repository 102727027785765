// Define variables
$primary-blue: #3a87c4;
$primary-blue-dark: #2c6da3;

.smart-bea-container {
  .dashboard-toggle-button {
    background-color: $primary-blue !important;
    border-color: $primary-blue !important;
    height: 36px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    padding: 0 16px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 160px !important;
    
    &:hover, 
    &:focus {
      background-color: $primary-blue-dark !important;
      border-color: $primary-blue-dark !important;
    }
  }

  /* Common styles */
  .ant-btn-primary {
    background-color: $primary-blue;
    border-color: $primary-blue;
    
    &:hover,
    &:focus {
      background-color: $primary-blue-dark;
      border-color: $primary-blue-dark;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 16px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .date-filter {
    display: flex;
    align-items: center;
  }

  .editable-row {
    .editable-cell {
      position: relative;
      
      .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
      }
      
      &:hover .editable-cell-value-wrap {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
      }
    }
  }

  /* ConsumptionDataComponent styles */
  .consumption-data-component {
    .add-form {
      margin-bottom: 20px;
    }
    
    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 20px;
    }
    
    .info-icon {
      margin-left: 4px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  /* BillingPeriodsComponent styles */
  .billing-periods-component {
    .billing-period-timeline {
      padding: 20px;
      
      .timeline-container {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        overflow-x: auto;
      }
      
      .timeline-header {
        display: flex;
        position: relative;
        height: 30px;
        border-bottom: 1px solid #f0f0f0;
      }
      
      .timeline-days {
        display: flex;
        width: 100%;
        
        .timeline-day {
          flex: 1;
          text-align: center;
          font-size: 12px;
          color: #999;
          position: relative;
          
          &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            width: 1px;
            height: 5px;
            background-color: #f0f0f0;
          }
        }
      }
      
      .timeline-utility {
        display: flex;
        margin-bottom: 15px;
        height: 40px;
        align-items: center;
        
        .utility-label {
          width: 100px;
          flex-shrink: 0;
          font-weight: 500;
        }
        
        .utility-period {
          flex-grow: 1;
          position: relative;
          height: 24px;
          background-color: #f9f9f9;
          border-radius: 3px;
          
          .period-bar {
            position: absolute;
            height: 100%;
            border-radius: 3px;
            padding: 0 8px;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: white;
            white-space: nowrap;
            
            &.electricity {
              background-color: #1890ff;
            }
            
            &.water {
              background-color: #13c2c2;
            }
            
            &.gas {
              background-color: #faad14;
            }
            
            &.waste {
              background-color: #52c41a;
            }
          }
        }
      }
      
      .billing-period-controls {
        margin-top: 30px;
      }
    }
  }

  /* OccupancyDataComponent styles */
  .occupancy-data-component {
    .occupancy-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    
    .occupancy-input-container {
      .mode-selector {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      
      .calendar-container {
        margin-top: 20px;
        
        .calendar-header {
          text-align: center;
          font-weight: bold;
          margin-bottom: 8px;
        }
        
        .calendar-day {
          height: 100px;
          border: 1px solid #f0f0f0;
          margin: 2px;
          position: relative;
          
          .day-number {
            position: absolute;
            top: 5px;
            right: 8px;
            font-size: 12px;
            color: #999;
          }
          
          .day-content {
            padding: 20px 5px 5px 5px;
            
            .occupancy-display {
              font-size: 12px;
              
              .label {
                color: #666;
                margin-right: 5px;
              }
              
              .value {
                font-weight: 500;
              }
            }
          }
        }
        
        .calendar-blank-day {
          height: 100px;
          margin: 2px;
        }
      }
    }
  }

  .ant-tabs-content {
    min-height: 300px;
  }

  .dashboard-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }

  .onboarding-component {
    width: 100%;
    
    .onboarding-content {
      max-width: 600px;
      margin: 0 auto;
      text-align: left;
    }

    .requirements-list {
      margin: 16px 0;
      padding-left: 24px;

      li {
        margin-bottom: 8px;
      }
    }

    .action-button {
      margin-top: 24px;
      text-align: center;
    }
  }

  .survey-summary-container {
    padding: 0 16px;
  }
} 