.sidebar-status-item-container {
  padding: 20px 14px 0 14px;
}

.sidebar-status-item-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.sidebar-status-stats-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: right;
  color: var(--mine-shaft);
}

.sidebar-status-item-stats-text-container {
  text-align: right;
}

.sidebar-status-item-progress-bar {
  .ant-progress-bg {
    background-color: var(--deep-cerulean);
  }
}
