.rapid-mapping-header {
  margin-bottom: 30px;
}

.rapid-mapping-header .header-text {
  color: var(--mine-shaft);
  display: inline;
  font-weight: 600;
  margin-right: 20px;
}

.rapid-mapping-header .header-subtext {
  color: var(--mine-shaft);
  display: inline;
}

.rapid-mapping-header .exit-button {
  width: 100%;
}

.content-left {
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.content-center {
  background: white;
  padding: 26px 40px 0;
  min-height: 580px;
}

.content-center .current-room-text {
  color: var(--scorpion);
  font-size: 30px;
  margin-bottom: 0;
}

.content-center .current-slot-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--scorpion);
}

.content-right .content-right-section {
  margin-left: 20px;
}

.content-right .content-right-section .content-right-section-body {
  width: 100%;
}

.ant-notification-notice-message {
  font-weight: normal;
  color: var(--mine-shaft);
}
