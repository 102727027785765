
.add-wifi-credentials-form {
  .ant-form-item-label {
    padding: 0 0 5px;
  }

  .ant-form-item {
    margin-bottom: 22px !important
  }
}
