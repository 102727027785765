@import "styles/mixinStyles";

.add-wifi-credentials-section {
  background-color: white;
  border: 1px solid var(--alto);
  min-height: 400px;

  .left-section {
    max-height: 65vh;
    overflow-y: auto;
  }

  .right-section {
    padding: 25px;
    max-height: 65vh;
    overflow-y: auto;
    border-left: 1px solid var(--alto);
  }
}

.footer-section {
  background-color: white;
  border: 1px solid var(--alto);
  border-top: unset;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .button-name-gateways {
    @include default-button;
    color: white;
    margin-right: 15px;
  }
}

.wifi-credentials-edit-title {
  padding: 9px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  background-color: var(--lily-white);
  border-right: 3px solid var(--deep-cerulean);
  
  .text {
    flex: auto;
    color: var(--deep-cerulean);
  }

  .detele-wifi-credentials {
    display: block;

    svg {
      font-size: 18px;
      color: var(--deep-cerulean);
    }
  }
}
