@import "styles/mixinStyles";

@include heading-1;

.breadcrumb-row-wrapper {
  margin-top: 45px;
  margin-bottom: 45px;
}

.exit-creation-mode {
  display: flex;
  justify-content: flex-end;
}
