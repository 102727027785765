.status-indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 3px;
}

.node-mobile-item-cell {
  padding: 5px !important;
}

.table-node-mobile {
  table {
    border-spacing: 0 !important;
  }
}
