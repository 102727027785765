.pre-cool-timer {
  margin-top: 24px;
  width: 100%;
  text-align: center;
  &-ellipse {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #e6f1f8;
    margin: 0 auto;
  }
  &-icon {
    .svg-icon svg {
      width: 24px;
      height: 24px;
      path {
        fill: rgb(0, 110, 183);
      }
    }
  }
  &-remaining-time {
    span.ant-typography {
      font-size: 12px;
    }
  }
}

.dry-icon-filled-color {
  svg path {
    fill: #006EB7 !important;
  }
}

.sun-icon-filled-color {
  svg path {
    fill: #FF8200 !important;
  }
}