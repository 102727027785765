.icon-bg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #E6EAEE;
}

.header {
    color: #313131;
}

.subtext {
    color: #5F5F5F;
}