$grid-breakpoints: (
  375: 375px, // Custom grid-breakpoints
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

$heading-font: "Open Sans Condensed", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

/** Colors */
$light-blue-100: #006eb7;
$light-blue: #3aa9dc;
$dark-blue: #002952;
$dark-blue-10: #e6eaee;
$light-orange: #f59c12;
$dark-orange: #e8472e;
$transparent: #ffffff00;
$white: #ffffff;

/** z-index */
$intercomZIndex: 2147483001;
$drawerZIndex: $intercomZIndex + 1000;
$pickerDropdownZIndex: $drawerZIndex + 1000;
