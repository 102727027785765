@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap");
@import "variables";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
}

#root {
  .ant-layout {
    min-height: 100vh !important;
  }
}

.ant-form-item {
  margin-bottom: 16px !important;
}

:root {
  --scorpion: #595959;
  --mine-shaft: #262626;
  --gray: #8c8c8c;
  --volcano: #fa541c;
  --burnt-sienna: #ed6854;
  --buttercup: #f59c12;
  --west-side: #fa8c16;
  --deep-cerulean: #006eb7;
  --alto: #d9d9d9;
  --lima: #52c41a;
  --mercury: #e8e8e8;
  --silver: #bfbfbf;
  --lily-white: #e7f9ff;
  --white: #ffffff;
  --black: rgba(0, 0, 0, 0.85);
  --error: #f5222d;
  --persian-green: #009D7F;
  --razzmatazz: #E10058;
  --calendula-gold: #fff9e6;
  --sapphire: #3A43A4;
  --daybreak-blue: #1890FF;
  --dark-orange: #FDEDEB;
  --dark-orange-100: #e8472e;
  --light-blue: #E6F1F8;
  --semi-blue: #B3BFCB;
  --dark-blue: #f4f4f5;
}

@import "common";
@import "utils.scss";
