.precool-comfortPlus {
  top: 0;
  padding: 0;
  .ant-message-notice-content {
    background-color: #eff9ee;
    padding: 12px 16px;
    width: 100%;
  }
  .ant-message-custom-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .anticon.anticon-close {
      color: #313131;
    }

    >span {
      &:not(.anticon.anticon-check-circle) {
        width: 100%;
      }
    }
  }
}
