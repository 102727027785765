.onDemandPrecoolRooms {
    background: #F2F8FB;
    border-radius: 8px;

    .title { 
        font-size: small;
        color: #5F5F5F;
    }
    .room {
        border-top: solid 1px #E6F1F8;
        font-weight: bold;
    }
    
    .ant-row {
        display: flex;
        justify-content: space-between;
        .roomName {
            order: 1;
        }

        .timeRemaining {
            order: 2;
        }

        .mode {
            font-size: 10px;
            color: #F4B14A;
            background: #FEF7ED;
            padding: 2px 6px 2px 6px;
            border-radius: 6px;
        }
    }
   
}