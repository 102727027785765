.category-collapse {
  border: unset;

  .ant-collapse-item {
    border: unset;

    .ant-collapse-header {
      background-color: white;
    }

    .ant-collapse-content {
      border: unset;
      padding: unset;

      .ant-collapse-content-box {
        padding: unset;
      }
    }
  }

  .category-collapse-panel {
    &.ant-collapse-item-active {
      .ant-collapse-content {
        height: initial !important;
      }
    }
  }

  .room-name {
    padding: 9px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .text {
      flex: auto;
      color: black;
    }

    .detele-room {
      display: none;
      font-size: 18px;
      color: var(--deep-cerulean);
    }

    &.active {
      background-color: var(--lily-white);
      border-right: 3px solid var(--deep-cerulean);

      .text {
        color: var(--deep-cerulean);
      }

      .detele-room {
        display: block;
      }
    }
  }
}

.new-category {
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 9px;
  margin-bottom: 9px;
}
