.height30 {
  height: 30%;
}

.bottomCards {
  height: calc((70% - 14px));
  :global {
    ul {
      height: calc((100% - 28px) / 3);
    }
  }
}

.acForm {
  height: calc((70% - 14px));
  :global {
    form {
      height: 100%;
    }
    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }
}
