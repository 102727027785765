@import "styles/variables";

.activate-comfortPlus {
  .ant-modal-body {
    padding: 16px;
  }

  .icon-key {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #f2f4f6;
    svg path {
      fill: #006eb7;
    }
  }

  .ant-form-item-required {
    &::before {
      content: none !important;
    }
  }
  .email-list {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #ccd4dc;
    background: #f2f4f6;

    .ant-form-item-label label {
      width: 100%;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }

    &-add-button {
      padding: 0;
      color: $light-blue-100;
      font-weight: 600;
    }
  }
}
