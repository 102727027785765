.precool-overall-status {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #e6f1f8;
  &-header {
    display: flex;
    padding-bottom: 6px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &-title {
      color: #484848;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
    }

    &-chip {
      display: flex;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #f2f8fb;
      color: #767676;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }

    &-see-all {
      margin-left: auto;
      margin-right: 0;
      .ant-btn.ant-btn-text {
        color: #006eb7;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        padding: 0;
      }
    }
  }

  &-temp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -2px;
    & p {
      margin: 0;
      padding: 0;
    }
    &-title {
      color: #5f5f5f;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
    &-content {
      display: flex;
      align-items: center;

      color: #006eb7;
      text-align: center;
      font-family: "Open Sans Condensed";
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      & .svg-icon svg path {
        fill: #006eb7;
      }
    }
  }

  &-list {
    display: flex;
    padding: 8px 12px;
    justify-content: space-evenly;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.9);

    &-item {
      display: flex;
      min-width: 70px;
      flex-direction: column;
      align-items: center;
      &-title {
        color: #8d8d8d;
        font-size: 12px;
        line-height: 20px; /* 166.667% */
      }
      &-content {
        color: #bbb;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        &.active {
          color: #313131;
        }
      }
    }
  }
}

.precool-overall-icon {
  &.svg-icon svg {
    width: 14px !important;
    height: 14px !important;
  }
  &.ac-mode-icon svg path {
    fill: #006eb7;
  }
  &.fan-icon svg path {
    fill: #3fc8a4;
  }
  &.schedule-icon svg path {
    fill: #ff8200;
  }
}
