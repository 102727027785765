@import "styles/mixinStyles";

.assign-keys {
  h1 {
    color: var(--mine-shaft);
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.keys-assign-footer {
  background-color: white;
  border: 1px solid var(--alto);
  border-top: none;
  padding: 16px;
  margin-bottom: 50px;

  .footer-right {
    display: flex;
    justify-content: flex-end;

    .create-keys {
      @include default-button;
    }
  }
}
