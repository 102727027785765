@import "styles/mixinStyles";

.node-item {
  background-color: white;
  border: 1px solid var(--silver);
  padding: 16px;
  margin: 16px;

  .title {
    color: gray;
    font-weight: bold;
  }

  .action-button {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
}

.node-status-desktop {
  border-radius: 5px;
  text-align: center;
  width: 80px;
  padding: 5px 5px;
  font-weight: bold;

  span {
    text-transform: uppercase;
    font-size: 12px;
    color: white;
  }

  &.ONLINE {
    background-color: var(--persian-green);
  }

  &.OFFLINE {
    background-color: var(--razzmatazz);
  }
}

.node-status-mobile {
  border-radius: 5px;
  text-align: center;
  width: 65px;
  padding: 0;
  font-weight: bold;
  height: 20px;
  margin: 0 3px;

  span {
    text-transform: uppercase;
    font-size: 10px;
    color: white;
    vertical-align: middle;
  }

  &.ONLINE {
    background-color: var(--persian-green);
  }

  &.OFFLINE {
    background-color: var(--razzmatazz);
  }
}