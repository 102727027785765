.room-actuator {
  margin-bottom: 24px;

  .ant-radio-group-solid {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: var(--deep-cerulean);
      border-color: transparent;
    }
  }
}

.ac-model {
  width: 350px;
}
