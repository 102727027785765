.excel-data-extractor {
  width: 100%;
  padding: 20px 0;
  max-width: 100%;

  .extraction-card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
    
    .ant-card-head {
      padding: 0 16px;
    }
    
    .ant-card-body {
      padding: 16px;
    }
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    
    .ant-spin {
      margin-bottom: 16px;
    }
  }

  .extracted-data-container {
    width: 100%;
    
    .table-container {
      width: 100%;
      overflow-x: auto;
      
      .ant-table-wrapper {
        width: 100%;
        
        .ant-table {
          width: 100%;
        }
      }
    }
    
    .action-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      
      button {
        margin-left: 8px;
      }
    }
  }
  
  .editable-cell {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 2px;
    transition: background-color 0.3s;
    
    &:hover {
      background-color: #f5f5f5;
    }
    
    &:focus {
      background-color: #e6f7ff;
      outline: none;
    }
  }
} 