@import "styles/mixinStyles";

.add-button {
  @include default-button;

  &.add-compressor {
    margin-left: 12px;
  }

  &.update-compressor {
    margin-right: 12px;
    background-color: white;
    color: var(--deep-cerulean);
  }
}

.infra-list-compressor {
  margin-left: -16px;
  margin-right: -16px;

  @include media-breakpoint-down(md) {
    margin: unset;
  }
}
