@import "styles/mixinStyles";

.test-list-item {
  span .status-icon {
    display: inline-block;
    text-align: center;
    width: 20px;
  }

  &:not(:last-child) .status-icon {
    height: 100%;
    margin: 2px 0;

    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 10px);
      border-left: 1px solid gray;
      display: flex;
      position: relative;
      margin-left: 50%;
      margin-top: 4px;
    }
  }

  &:not(:last-child) .loading-status-icon {
    margin-bottom: 0 !important;

    &::after {
      margin-top: 0px;
    }
  }
}

.testRunList {
  position: relative;
  margin-top: auto;
  &.has-test-list {
    min-height: 100vw;
  }
}

.test-function-button {
  background-color: white !important;
}
