.occupancy {
  margin-bottom: 24px;

  .preview-section {
    padding: 0 16px;

    .preview-element {
      margin: 24px 0;
    }
  }
}
